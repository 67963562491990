import {
  LoadI18nMessagesProps,
  loadIntlMessages,
} from "@/utils/loadIntlMessages";

export const getStaticProps = (ctx: LoadI18nMessagesProps) => {
  const intlMessages = loadIntlMessages(ctx);

  return {
    props: { intlMessages, status: 404 },
  };
};

export { Error as default } from "@/components/pages/error/Error";
